// Import your sidebar component here

import {
  SidebarContent,
  useUserDropdownOptions,
} from '@/components/mygindumac/UserMenu';

function Sidebar() {
  const { options, isMyGindumacPage } = useUserDropdownOptions();

  if (isMyGindumacPage) {
    return (
      <div className='sticky top-20 hidden h-[calc(100vh-5rem)] w-64 border-r-2 2xl:inline-block'>
        <SidebarContent options={options} />
      </div>
    );
  }
  return null;
}

export default Sidebar;
